import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import Enga5Page from "~/components/Enga/Enga5Page";

export default function () {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Construire des biens qui font du bien, c’est vous proposer des projets certifiés NF Habitat, conçus avec des matériaux durables et respectueux de l’environnement.",
    },
  };

  return (
    <>
      <Title>Engagement Cogedim - 100% d’espaces extérieurs</Title>
      <MetaTags tags={[metaDesc]} />
      <Enga5Page />
    </>
  );
}
