import FirstScreen from "~/components/Enga/Components/FirstScreen";
import AutopromoContainer from "~/components/Enga/Components/AutopromoContainer";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";

import "./EngaPages.css";
import "./EngaSinglePage.css";

import IconPlayArrow from "~/img/icons/play_arrow.svg";
import IconArrowForward from "~/img/icons/arrow_forward.svg";
import { clientOnly } from "@solidjs/start";
import GtmVars from "../shared/Trackers/GtmVars";
const CarouselImages = clientOnly(() => import("./Components/CarouselImages"));

export default function Enga5Page() {
  const gtmValues = () => {
    return {
      "all.pageType": "other",
      "all.mainCategory": "Engagement 5",
      "all.category": "Non défini",
      "all.subCategory": "Non défini",
      "all.subsubCategory": "Non défini",
      "all.subsubsubCategory": "Non défini",
      template: "nos-engagements",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article class="page-engagement page-engagement-5">
        <FirstScreen
          visual="/images/engagements/page-engagement-5-visual.jpg"
          visualMobile="/images/engagements/page-engagement-5-visual-mobile.jpg"
          title="100% d’espaces extérieurs"
          prevLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-amenagements-intelligents.html"
          nextLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/mobilite.html"
        />

        <EngaDetails />
        <section class="engagement-rows">
          <h2>Les preuves de nos engagements</h2>
          <EngaRow1 />
          <EngaRow2 />
        </section>
        <VideoExcerpt />

        <section class="next-nav">
          <a
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/mobilite.html",
            )}
            class="btn"
          >
            Voir l'engagement suivant{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </section>
      </article>

      <AutopromoContainer />
    </>
  );
}

function EngaDetails() {
  const carouselImages: string[] = [
    "/images/engagements/page-engagement-5-slider-1.jpg",
    "/images/engagements/page-engagement-5-slider-2.jpg",
    "/images/engagements/page-engagement-5-slider-3.jpg",
    "/images/engagements/page-engagement-5-slider-4.jpg",
    "/images/engagements/page-engagement-5-slider-5.jpg",
    "/images/engagements/page-engagement-5-slider-6.jpg",
    "/images/engagements/page-engagement-5-slider-7.jpg",
    "/images/engagements/page-engagement-5-slider-8.jpg",
    "/images/engagements/page-engagement-5-slider-9.jpg",
  ];
  return (
    <>
      <section class="engagement-details">
        <div class="introduction">
          <div class="tags">
            <span>Pour votre bien être</span>
          </div>
          <p>
            Construire des biens qui font du bien, c’est intégrer des espaces
            extérieurs dans tous nos logements, comme des terrasses
            aménageables.
          </p>
        </div>

        <div class="media-part">
          <CarouselImages images={carouselImages} />
        </div>
      </section>
    </>
  );
}

function EngaRow1() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Des terrasses plus grandes et aménageables</h3>
          <p>
            100 % de nos logements, à partir du T2, bénéficient d’un espace
            extérieur privatif d’une profondeur min d’1m40.
          </p>
          <ul>
            <li>
              La surface du balcon d’un appartement est au minimum égale à 10%
              de la surface de l ’appartement.
            </li>
            <li>
              Les architectes Cogedim veillent à la conception des espaces
              extérieurs afin d’assurer une habitabilité maximale.
            </li>
          </ul>
          <p class="excerpt">
            <strong>100 % de nos logements</strong> ont un espace extérieur
            privatif à partir du 2P
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-5-visuel1.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function EngaRow2() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Des espaces collectifs végétalisés</h3>
          <p>
            100% de nos nouveaux projets de logement bénéficient d’espaces
            extérieurs collectifs végétalisés.
          </p>
          <p>
            Ces espaces extérieurs communs font l’objet d'une démarche de
            développement de la biodiversité sur mesure et adaptée à leur
            situation géographique, à la fois sur leur dimensionnement, et sur
            la qualité de leur traitement végétal.
          </p>
          <p class="excerpt">
            <strong>100% de nos nouveaux logements</strong> ont des espaces
            extérieurs collectifs végétalisés
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-5-visuel2.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function VideoExcerpt() {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <section class="video-excerpt">
        <div class="video-row">
          <div class="visual">
            <img
              src={imgProxy(
                "/images/engagements/page-engagement-5-excerpt-video.jpg",
                `size:675:480/resizing_type:fill`,
              )}
              height="267"
              width="375"
              alt=""
            />
            <button
              class="btn btn-icon"
              onClick={() => {
                setMediaOverlay(
                  "embed",
                  `<iframe height="100%" width="100%" src="https://www.youtube.com/embed/Q4ywiEo63Xk" allowfullscreen />`,
                );
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPlayArrow />
              </i>
            </button>
          </div>
          <div class="video-details">
            <h4>
              Nos M<sup>2</sup> préférés extérieurs
            </h4>
            <p>
              À Mérignac, Françoise et Alain profitent chaque jour de leur salon
              de thé à ciel ouvert.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
